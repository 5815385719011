<template>
  <div>
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perClosedPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t("tane göster") }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="closedTicketsTable"
        class="position-relative"
        :items="getClosedTickets"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :per-page="perClosedPage"
        :current-page="currentClosedPage"
        empty-text="Kayıt bulunamadı"
      >
        <template #cell(sKayitNo)="data">
          {{ data.item.ticket.sKayitNo }}
        </template>
        <template #cell(sKonu)="data">
          {{ data.item.ticket.sKonu }}
        </template>
        <template #cell(ticketOncelikTuru)="data">
          <b-badge
            :variant="
              data.item.ticket.ticketOncelikTuru == 2 ? 'info' : 'danger'
            "
            class="badge"
          >
            <span>{{
              resolveOncelikTur(data.item.ticket.ticketOncelikTuru)
            }}</span>
          </b-badge>
        </template>
        <template #cell(departmentTuru)="data">
          {{ resolveDepartmanTur(data.item.ticket.departmentTuru) }}
        </template>
        <template #cell(dtOlusTarih)="data">
          {{ formatDateToDMYT(data.item.ticket.dtOlusTarih) }}
        </template>
        <template #cell(ilgiliPersonelAd)="data">
          {{ data.item.ilgiliPersonelAd }}
        </template>
        <template #cell(actions)="data">
          <b-button-group>
            <b-button
              size="sm"
              v-if="isAdmin()"
              @click="
                $router.push({
                  name: 'manage-support-detail-close',
                  params: { detailId: data.item.ticket.id },
                })
              "
              variant="primary"
            >
              <span class="align-middle">Detay</span>
            </b-button>
          </b-button-group>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ closedDataMeta.of }} öğeden {{ closedDataMeta.from }} -
              {{ closedDataMeta.to }} arası gösteriliyor</span
            >
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentClosedPage"
              :total-rows="toplamClosedTicket"
              :per-page="perClosedPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { getUserRole, Role } from '@/auth/utils'
import useSupport from './useDestek';

export default {
  methods: {
    isAdmin() {
      return getUserRole() === Role.SuperAdmin
    },
  },
  setup() {
    const {
      fetchClosedTickets,
      getClosedTickets,
      tableColumns,
      perClosedPage,
      currentClosedPage,
      toplamClosedTicket,
      closedDataMeta,
      closedTicketsTable,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      resolveDepartmanTur,
      resolveOncelikTur,

      formatDateToDMYT,
    } = useSupport();

    return {
      fetchClosedTickets,
      getClosedTickets,

      perClosedPage,
      currentClosedPage,
      toplamClosedTicket,
      closedDataMeta,
      closedTicketsTable,
      tableColumns,

      perPageOptions,
      sortBy,
      isSortDirDesc,
      resolveDepartmanTur,
      resolveOncelikTur,

      formatDateToDMYT,
    }
  },
}
</script>

<style>
</style>
